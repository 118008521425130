import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeHeader from "../components/homeHeader"
import RichContentBlock from "../components/richContentBlock"
import TitleBlock from "../components/titleBlock"
import BenefitsBlock from "../components/benefitsBlock"
import StoriesBlock from "../components/storiesBlock"
import CustomTabs from "../components/customTabs"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { LanguageContext, languages } from "../context/langContext"
import { getHomePath } from "../utils/path"
import LogoBlock from "../components/logoBlock"
import ImageGallery from "../components/imageGallery"
import CtaBlock from "../components/ctaBlock"

const IndexPage = ({ data: { page, site } }) => {
  const locale = "it"
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  return (
    <LanguageContext.Provider value={languages[locale]}>
      <Layout locale={locale} i18nPaths={i18nPaths}>
        <SEO lang={locale} />
        <HelmetDatoCms seo={page.seoMetaTags} />
        <HomeHeader
          title={page.title}
          subtitle={page.subtitle}
          callToAction={page.callToAction}
          text={page.text}
          videoUrl={page.videoUrl}
          locale={locale}
        />
        <Content>
          {page.contentTop.map(section => (
            <section key={section.id}>
              {section.model.apiKey === "cta_block" && (
                <CtaBlock content={section} />
              )}
              {section.model.apiKey === "rich_content_block" && (
                <RichContentBlock content={section} />
              )}
              {section.model.apiKey === "image_gallery" && (
                <ImageGallery images={section.images} />
              )}
              {section.model.apiKey === "title_block" && (
                <TitleBlock content={section} />
              )}
              {section.model.apiKey === "benefits_block" && (
                <BenefitsBlock content={section} />
              )}
              {section.model.apiKey === "logo_block" && (
                <LogoBlock images={section.images} />
              )}
            </section>
          ))}
        </Content>
        <Content>
          <CustomTabs tabs={page.tabs} kind="richContent" />
        </Content>
        <Content>
          {page.contentBottom.map(section => (
            <section key={section.id}>
              {section.model.apiKey === "cta_block" && (
                <CtaBlock content={section} />
              )}
              {section.model.apiKey === "image_gallery" && (
                <ImageGallery images={section.images} />
              )}
              {section.model.apiKey === "rich_content_block" && (
                <RichContentBlock content={section} />
              )}
              {section.model.apiKey === "title_block" && (
                <TitleBlock content={section} />
              )}
              {section.model.apiKey === "benefits_block" && (
                <BenefitsBlock content={section} />
              )}
              {section.model.apiKey === "stories_block" && (
                <StoriesBlock content={section} locale={locale} />
              )}
            </section>
          ))}
        </Content>
      </Layout>
    </LanguageContext.Provider>
  )
}

const Content = styled.div`
  margin: 0;
`

export default IndexPage

export const query = graphql`
  query HomePageItQuery {
    site: datoCmsSite {
      locales
    }
    page: datoCmsHomePage(locale: { eq: "it" }) {
      title
      subtitle
      text
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      videoUrl
      callToAction {
        ... on DatoCmsLink {
          ...LinkDetails
        }
        ... on DatoCmsExternalLink {
          id
          model {
            apiKey
          }
          anchorText
          url
          blank
        }
      }
      tabs {
        ... on DatoCmsRichContentBlock {
          ...RichContentDetails
        }
      }
      contentTop {
        ... on DatoCmsCtaBlock {
          id
          backgroundColor {
            hex
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          ...GalleryDetails
        }
        ... on DatoCmsLogoBlock {
          id
          images {
            url
            originalId
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleBlock {
          title
          label
          text
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsBenefitsBlock {
          title
          label
          layout
          id
          backgroundColor {
            hex
          }
          benefits {
            ... on DatoCmsBenefit {
              id
              model {
                apiKey
              }
              title
              text
              icon {
                url
                alt
              }
            }
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsRichContentBlock {
          ...RichContentDetails
        }
      }
      contentBottom {
        ... on DatoCmsCtaBlock {
          id
          backgroundColor {
            hex
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          ...GalleryDetails
        }
        ... on DatoCmsTitleBlock {
          title
          label
          text
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsBenefitsBlock {
          title
          label
          layout
          id
          backgroundColor {
            hex
          }
          benefits {
            ... on DatoCmsBenefit {
              id
              model {
                apiKey
              }
              title
              text
              icon {
                url
                alt
              }
            }
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsStoriesBlock {
          title
          label
          text
          id
          stories {
            ... on DatoCmsStory {
              id
              model {
                apiKey
              }
              title
              country
              companyName
              slug
              meta {
                publishedAt(locale: "it")
              }
              heroImage {
                sizes(imgixParams: { fit: "crop", w: "350", h: "260" }) {
                  base64
                  width
                  height
                  src
                  srcSet
                  sizes
                }
                alt
                url
              }
            }
          }
          callToAction {
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsRichContentBlock {
          ...RichContentDetails
        }
      }
    }
  }

  fragment RichContentDetails on DatoCmsRichContentBlock {
    label
    title
    text
    video
    alignment
    benefits {
      ... on DatoCmsBenefit {
        id
        model {
          apiKey
        }
        title
        text
        icon {
          url
          alt
        }
      }
    }
    callToAction {
      ... on DatoCmsLink {
        ...LinkDetails
      }
      ... on DatoCmsExternalLink {
        id
        model {
          apiKey
        }
        anchorText
        url
        blank
      }
    }
    backgroundColor {
      hex
    }
    icon {
      url
      alt
    }
    image {
      url
      alt
    }
    backgroundImage {
      url
    }
    id
    model {
      apiKey
    }
  }

  fragment GalleryDetails on DatoCmsImageGallery {
    id
    images {
      mimeType
      url
      originalId
      customData
    }
    model {
      apiKey
    }
  }

  fragment LinkDetails on DatoCmsLink {
    id
    model {
      apiKey
    }
    anchorText
    page {
      ... on DatoCmsPage {
        id
        model {
          apiKey
        }
        slug
        locale
        _allSlugLocales {
          value
          locale
        }
      }
      ... on DatoCmsStory {
        id
        model {
          apiKey
        }
        slug
        locale
        _allSlugLocales {
          value
          locale
        }
      }
    }
  }
`
